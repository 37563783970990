<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <BlueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>


  </section>
</template>
   
  <!-- #################################################################################### -->
  <!-- ###### Sección de Scripts                                                     ###### -->
  <!-- #################################################################################### -->
<script>
import { Role } from "@/router/role.js";
import BlueHeader from "../../../../components/BlueHeader.vue";

export default {
  name: 'Logistics',
  components: { BlueHeader },
  data: () => ({

    itemsHeader: [
      {
        name: 'Pedidos y traslados',
        link: { name: 'modules.analytics.logistics.pedidosTraslados' },
        rol: Role.Analitica_Logistica_PedidosTraslados,
      },
      {
        name: 'Productividad',
        link: { name: 'modules.analytics.logistics.productividad' },
        rol: Role.Analitica_Logistica_Productividad,
      },
    ]
  }),

}
</script>
   
  <!-- #################################################################################### -->
  <!-- ###### Sección de Styles                                                      ###### -->
  <!-- #################################################################################### -->
<style scoped>
.subHeader-content {
  width: 100%;
}
</style>